import React, { Component } from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Timeline from 'components/timelineComponent';
import { Image } from 'components/Image';
import {
  showElemWithAria, hideElementWithAria
} from 'utils/animation';

import './chapter-2-scene-6-part-2.scss';
import { TweenMax } from 'gsap';

class Chapter2Scene6Part2 extends React.Component {
  render() {
    const { CourtHouse } = this.props.data
    return (
      <div className="scene6p2">
        <div className="scene6p2__img">
          <Image image={CourtHouse} showAria={this.props.active} alt="Jefferson County Courthouse aerial view"/>
        </div>
        <div className="scene6p2__court" ref="court">
          <h3>Jefferson County<br/>Courthouse</h3>
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {
  const { court } = context.refs
  TweenMax.set(court, {...hideElementWithAria})
  timeline.fromTo(court, 1.5, {y: '+=50'}, {y:'-=50', ...showElemWithAria, ...easeOut})
}

const query = graphql`
  query {
    CourtHouse: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-jefferson-county-courthouse@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }
  }
`

const SceneComponent = Timeline(Chapter2Scene6Part2, {animation})

export default (props) => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props}/>
};
