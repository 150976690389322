import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import SelmaScene1Modal from 'components/scenes/chapter-2/scene-2/SelmaScene1Modal';
import scene from 'components/scene';

import Clip from './ch2-SCENE-01-Annie_Lee_Cooper_Tries_To_Register_To_Vote.mp4';

const Chapter2Scene2 = ({data}) => (
  <div>
    <MovieScene
      sceneNumber={1}
      sceneDescription={`Annie Lee Cooper Tries to Register To Vote`}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 1</span>
      }
      firstImage={data.firstImage}
      lastImage={data.lastImage}
      videoSrc={Clip}
      modalComponent={() => (
        <SelmaScene1Modal />
      )}
    />

  </div>
);

const query = graphql`
  query {
      firstImage: file(relativePath: { eq: "chapter-2/scene-2/images/banner@2x.png" }) {
        ...largeImage
      }
      lastImage: file(relativePath: { eq: "chapter-2/scene-2/images/banner@2x.png" }) {
        ...largeImage
      }
  }
`;

const Component = scene(Chapter2Scene2, {stopAtStart: true, stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
