import React from 'react';
import './act-modal.scss';

const ActModal = () => (
  <div className="act-modal">
    <h2>
      1964<br/>Civil Rights Act
    </h2>
    <h5 className="highlight">
      Title II
    </h5>
    <p className="alt">
      Outlawed segregation in all public accommodations on the basis of race, color, religion or national origin.
    </p>
    <h5 className="highlight">
      Title VII
    </h5>
    <p className="alt">
      Outlawed discrimination by employers on the basis of race, color, religion, national origin, and also, for the first time, by sex/gender.
    </p>
  </div>
);

export default ActModal;
