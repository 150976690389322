import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import {TimelineMarkers} from 'utils/scene'
import scene from 'components/scene'

import './chapter-2-scene-3.scss';
import SplitView from 'templates/SplitView';
import Bullet from './images/x.svg';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';

import {tabbable} from 'utils/accessibility'

const C2S3 = class extends React.Component {

  render() {
    const {data} = this.props;
    const {Commission, HoseaGuice, Vouch, VotingPrevention, Doodle} = data;
    return (
      <div className="chapter-2-scene-3">
        <div ref="commissionSection">
          <FullScreenContainer className="commission">
            <div className="commission__text" ref="commissionText">
              <h3 {...tabbable}>
                  The 1957 Civil Rights Act allowed black voters to testify to a federal Civil Rights Commission (CRC) about the discrimination they faced.
              </h3>
            </div>
            <div className="commission__image" ref="commissionImage">
              <Image image={Commission} showAria alt="Chairman John A. Hannah, second from left, reads a joint statement issued by the Federal Civil Rights Commission as it ended its investigation December 9, 1958 into alleged voting rights violations. Left to right: J. Ernest Wilkins, Hannah, Robert G. Storey and John S. Battle. (AP Photo)"/>
            </div>
            <div className="commission__wilkins">
              <div {...tabbable} ref="commissionWilkins">
                <h4>J. Ernest Wilkins</h4>
                <h5>-The only African American<br/>Civil Rights Commissioner</h5>
                <div className="wilkins-doodle">
                  <Image image={Doodle} alt="Doodle"/>
                </div>
              </div>
            </div>
          </FullScreenContainer>
        </div>

        <div {...tabbable} ref="hoseaSection">
          <FullScreenContainer className="hosea-guice">
            <div className="hosea-img" ref="hoseaImg">
              <Image image={HoseaGuice} alt="Hosea Guice"/>
            </div>
            <ScrollContainer className="hosea-description">
              <div className="subhead" ref="hoseaTitle">
                  Hosea Guice, a 55-year-old farmer, was <span><br/>rejected four times</span>. He told the Commission:
              </div>
              <h3 ref="hoseaParagraph">
                  Well I have never been arrested and always have been a law-abiding citizen, [with] no mental deficiency...<span>I was just a Negro</span>. That’s all.
              </h3>
            </ScrollContainer>
          </FullScreenContainer>
        </div>
        <div className="consequences" ref="consequencesSection">
          <SplitView
            leftPanel={
              <div {...tabbable} className="consequences__text">
                <div className="subhead" ref="consequencesHead">
                    But the CRC had no power to enable law-abiding citizens like Mr. Guice to vote. <span>Nor could it prevent the names of attempted registrants from being published in local newspapers</span>, exposing them to economic hardship.
                </div>

                <div className="fact" ref="fact1">
                  <Bullet/><p className="alt">Some, like Annie Lee Cooper, lost their jobs.</p>
                </div>
                <div className="fact" ref="fact2">
                  <Bullet/><p className="alt">Black tenants were evicted by their white landlords.</p>
                </div>
                <div className="fact" ref="fact3">
                  <Bullet/><p className="alt">Black business owners were denied bank loans.</p>
                </div>
              </div>
            }
            rightPanel={
              <div className="consequences__image" ref="consequencesImg">
                <Image image={VotingPrevention} showAria alt="Man registers to vote (with visible notice)"/>
              </div>
            }
          />
        </div>
        <div className="sponsor" ref="sponsorSection">
          <SplitView
            leftPanel={
              <div className="sponsor__image" ref="sponsorImage">
                <Image showAria image={Vouch} alt="A courthouse official, left, administers the oath to voter registration applicants in Montgomery, Alabama on Feb. 9, 1965. Dr. Martin Luther King Jr. led a group of almost 200 African Americans to the registrar’s office in a voter drive. (AP Photo/Horace Cort)"/>
              </div>
            }
            rightPanel={
              <div {...tabbable} className="sponsor__text">
                <div className="subhead" ref="sponsorHead">
                  New registrants to vote needed a sponsor who was already a voter to “vouch” for them. <span>Whites refused to vouch for black registrants.</span>
                </div>

                <p className="alt" ref="sponsorParagraph">
                    When the small number of black voters tried to vouch for other African Americans, registrars forbade them from doing so.
                </p>
              </div>
            }
          />
        </div>
      </div>
    );
  }
};

const query = graphql`
  query {
    Commission: file(relativePath: { eq: "chapter-2/scene-3/images/ch-2-image-crc@2x.png"}) {
      childImageSharp {
        fluid(maxWidth: 2534) {
          ...selmaFluidImage
        }
      }
    }
    HoseaGuice: file(relativePath: { eq: "chapter-2/scene-3/images/ch-2-image-hosea-guice@2x.png"}) {
      childImageSharp {
        fluid(maxWidth: 1202) {
          ...selmaFluidImage
        }
      }
    }

    Vouch: file(relativePath: { eq: "chapter-2/scene-3/images/vouch@2x.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    VotingPrevention: file(relativePath: { eq: "chapter-2/scene-3/images/ch-2-image-voting-prevention@2x.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Doodle: file(relativePath: { eq: "chapter-2/scene-3/images/ch-2-doodle-arrow-crc@2x.png"}) {
      childImageSharp {
        fluid(maxWidth: 170) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    commissionText, commissionImage, commissionWilkins,
    hoseaImg, hoseaTitle, hoseaParagraph,
    consequencesHead, fact1, fact2, fact3, consequencesImg, sponsorImage, sponsorHead,
    sponsorParagraph, commissionSection, hoseaSection, consequencesSection, sponsorSection
  } = context.refs;

  hideAllRefs(context.refs, ['commissionImage','commissionSection', 'consequencesImg', 'sponsorImage']);

  TweenMax.set(commissionImage, {transformOrigin: 'right top'});

  TweenMax.set(commissionText, {y: 100})
  TweenMax.set(commissionWilkins, {y: 100})

  timeline.to(commissionText, 1.5, {y: 0, ...showElemWithAria, ...easeOut});
  timeline.to(commissionWilkins, 1.5, {y: 0, ...showElemWithAria, ...easeOut}, '-=1.25');

  timeline.markers.createTimelineMarker();

  timeline.to(commissionText, 1.5, {y: '-=50', ...hideElementWithAria, ease: Expo.easeInOut});
  timeline.to(commissionImage, 1.5, {y: '-=200', scale: 0.74, autoAlpha: 0.6, attr: {'aria-hidden': false},  ease: Expo.easeInOut}, '-=1.5');
  timeline.to(commissionWilkins, 1.5, {...hideElementWithAria, ease: Expo.easeInOut}, '-=1.5');

  timeline.to(hoseaSection, 0.5, {...showElemWithAria}, "-=0.5")

  timeline.fromTo(hoseaImg, 1.5, {y: '+=100'}, {...showElemWithAria, y: '-=100', ...easeOut}, "-=0.25");
  timeline.fromTo(hoseaTitle, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=1.5");
  timeline.fromTo(hoseaParagraph, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=1.25");

  timeline.markers.createTimelineMarker();

  timeline.to(commissionSection, 1, {...hideElementWithAria})
  timeline.to(hoseaSection, 1, {...hideElementWithAria}, "-=1")

  timeline.to(consequencesSection, 1, {...showElemWithAria})
  timeline.fromTo(consequencesHead, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=0.5");
  timeline.fromTo(fact1, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=1.25");
  timeline.fromTo(fact2, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=1.25");
  timeline.fromTo(fact3, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, "-=1.25");

  timeline.markers.createTimelineMarker();

  timeline.to(consequencesSection, 1, {...hideElementWithAria})

  // TweenMax.set(sponsorImage, {y: 100})
  TweenMax.set(sponsorHead, {y: 100})
  TweenMax.set(sponsorParagraph, {y: 100})
  timeline.to(sponsorSection, 1, {...showElemWithAria})
  timeline.to(sponsorHead, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=0.5");
  timeline.to(sponsorParagraph, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=1.25");

  timeline.markers.createTimelineMarker();
};

const SceneComponent = scene(timeline(C2S3, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props}/>;
};
