import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';
import scene from 'components/scene';
import {tabbable} from 'utils/accessibility'

import SplitView from 'templates/SplitView';

import GeoPin from './images/pin.svg';

import './chapter-2-scene-q.scss';

class Chapter2IQ extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      layout: 'questions'
    }
  }

  setAnswer = (layout) => {
    this.setState({layout})
    requestAnimationFrame( () => {
      document.getElementById(layout).querySelector('.content').focus()
    })
  }

  render() {
    const {
      bannerFollow,
      bannerProtest,
      choiceFollow,
      choiceProtest,
      interactiveBg,
    } = this.props.data;

    const { layout } = this.state

    return (
      <div className="interactive-section">
        <div>
          <FullScreenContainer className="interactive-question">
            <ScrollContainer>
              <div className="interactive-question__content">
                <Image className="bg-image" image={interactiveBg} alt="Street-level map of Selma, Alabama"/>

                <div {...tabbable} className="interactive-question__box">
                  <p className="label highlight">Question:</p>
                  <p className="alt">African American adults in Selma who wished to vote faced great challenges.</p>
                  <p className="subhead">If you were living in Selma in 1965, what would you have done to secure your right to vote?</p>
                </div>

                <div className="interactive-question__shape-1 interactive-question__question-box">
                  <div className="content-image">
                    <Image image={bannerFollow} alt="Banner Follow"/>
                  </div>
                  <div className="content">
                    <p>Follow the rules</p>
                    <button aria-label="Follow the Rules" onClick={() => this.setAnswer('followTheRules')}>Choose</button>
                    <div className="pointer"></div>
                    <div className="interactive-question__pin-1">
                      <GeoPin />
                    </div>
                  </div>
                </div>

                <div className="interactive-question__shape-2 interactive-question__question-box">
                  <div className="content-image">
                    <Image image={bannerProtest} alt="Banner Protest"/>
                  </div>
                  <div className="content">
                    <p>Join a protest</p>
                    <button aria-label="Join a Protest" onClick={() => this.setAnswer('joinProtest')}>Choose</button>
                    <div className="pointer"></div>
                    <div className="interactive-question__pin-2">
                      <GeoPin />
                    </div>
                  </div>
                </div>

                <div className="interactive-question__shape-3 interactive-question__question-box">
                  <div className="content">
                    <p>Do nothing</p>
                    <button aria-label="Do Nothing" onClick={() => this.setAnswer('doNothing')}>Choose</button>
                  </div>
                </div>
              </div>
            </ScrollContainer>

          </FullScreenContainer>
        </div>

      {layout === 'followTheRules' &&
        <div className="interactive-question__selection" id="followTheRules">
          <SplitView className="follow-rules"
            leftPanel={(
              <div {...tabbable} className="content">
                <p className="label">Follow the rules</p>
                <p className="highlight">Study for the literacy test,<br />wait in line, and apply.</p>
                <p className="alt">
                  Even the most well-prepared voting hopefuls were rejected. Registrars had the discretion to make the literacy test harder, or even nearly impossible if they chose, and they could subjectively deny any application. <br /><br />
                  Some voting hopefuls didn’t even have the chance to take the literacy test—they were attacked by Sheriff Clark’s deputies before they made it to the registrar’s door.<br /><br />
                  Those who succeeded in registering often had their names and addresses published in the local paper, which put them at the risk of retaliation from their employers and others in the community opposed to equal voting rights.</p>
                <div className="buttons">
                  <div className="button">
                    <button className="first" onClick={() => this.setAnswer('questions')}>CHOOSE AGAIN</button>
                  </div>
                  <div className="button">
                    <a href="/chapter-3"><button>Continue to chapter 3</button></a>
                  </div>
                </div>
              </div>
            )}
            rightPanel={(
              <Image image={choiceFollow} alt="African American man registering to vote"/>
            )}
          />
        </div>
      }

      {layout === 'joinProtest' &&
        <div className="interactive-question__selection" id="joinProtest">
          <SplitView className="join-protest"
            leftPanel={(
              <div {...tabbable} className="content">
                <p className="label">Join the protests</p>
                <p className="highlight">… and stand up for your rights.</p>
                <p className="alt">
                  There were additional ways to stand up, including education, prayer meetings, attending/giving speeches, distributing meeting announcements and protest communications, and soliciting media attention.
                </p>
                <div className="buttons">
                  <div className="button">
                    <button className="first" onClick={() => this.setAnswer('questions')}>CHOOSE AGAIN</button>
                  </div>
                  <div className="button">
                    <a href="/chapter-3"><button>Continue to chapter 3</button></a>
                  </div>
                </div>
              </div>
            )}
            rightPanel={(
              <Image showAria image={choiceProtest} alt={`These youngsters carry signs protesting Dallas County Sheriff Jim Clark and segregation after more than 250 persons were arrested during a march on the courthouse in Selma, Alabama, Feb. 1, 1965. Those arrested included Dr. Martin Luther King, Jr., 1964 winner of the Nobel Peace. About 400 school-age blacks were taken into custody and turned over to truant officials. (AP Photo/Bill Hudson)`}/>
            )}
          />
        </div>
      }

      {layout === 'doNothing' &&
        <div className="interactive-question__selection" id="doNothing">
          <SplitView className="do-nothing"
            leftPanel={(
              <div {...tabbable} className="content">
                <p className="label">Do Nothing</p>
                <p className="highlight">While this could feel like the safest and<br />easiest answer, voting is the way we<br />participate in our democracy.</p>
                <p className="alt">
                  Doing nothing in 1965 would have meant allowing African Americans to continue being attacked and treated as second class citizens.<br /><br />
                  Every voice possible was needed to bring about change.
                </p>
                <div className="buttons">
                  <div className="button">
                    <button onClick={() => this.setAnswer('questions')}>CHOOSE AGAIN</button>
                  </div>
                  <div className="button">
                    <a href="/chapter-3"><button>Continue to chapter 3</button></a>
                  </div>
                </div>
              </div>
            )}
            rightPanel={(
              <div className="empty"></div>
            )}
          />
        </div>
      }


      </div>
    );
  }
}

export const query = graphql`
  query {
    bannerFollow: file(relativePath: { eq: "chapter-2/scene-q/images/ch-2-banner-choice-follow@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 299) {
          ...selmaFluidImage
        }
      }
    }
    bannerProtest: file(relativePath: { eq: "chapter-2/scene-q/images/ch-2-banner-choice-protest@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 299) {
          ...selmaFluidImage
        }
      }
    }
    choiceFollow: file(relativePath: { eq: "chapter-2/scene-q/images/ch-2-image-choice-follow@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }
    choiceProtest: file(relativePath: { eq: "chapter-2/scene-q/images/ch-2-image-choice-protest@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }
    interactiveBg: file(relativePath: { eq: "chapter-2/scene-q/images/ch-2-interactive-bg@2x.png" }) {
      ...largeImage
    }
  }
`;

const Component = scene(Chapter2IQ)

export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props}/>;
};
