import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import timeline from 'components/timelineComponent';
import {Image} from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import VerticalTimeline from 'components/VerticalTimeline';
import SplitView, {viewTypes} from 'templates/SplitView';
import {getPercentageBetweenBounds} from 'utils';

import scene from 'components/scene';
import {tabbable} from 'utils/accessibility'
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, showElement, hideElement
} from 'utils/animation';

import Part1 from './Chapter2Scene6Part1';
import Part2 from './Chapter2Scene6Part2';

import './chapter-2-scene-6.scss';
import { removeTab, addTab } from '../../../../utils/animation';

const duration = 29 // important; must update if animation is changed
const timelineStart = 6.75
const timelineDuration = 12

const C2S6 = class extends React.Component {
  render() {
    const {position, data} = this.props;
    const {
      SclcBg, SclcFg, LaFayetteMap, BernardLaFayette, ColiaLaFayette,
      DvclMap, DvclFred, DvclAmelia, Protest, LaFayetteBeaten, Students,
      timelinesYaml,
    } = data;
    const verticalTimelinePosition = getPercentageBetweenBounds(position, (timelineStart/duration), ((timelineStart + timelineDuration)/duration));


    return (
      <div className="chapter-2-scene-6">
        <div className="activists" ref={(ref) => this.activists = ref}>
          <SplitView
            viewType={viewTypes.TWO_THIRDS}
            fullWidthPanel={(
              <div className="activists__bg" ref={(ref) => this.activistsBg = ref}>
                <Image image={SclcBg} showAria
                  alt='SCLC Members Sing at the March on Washington More than 200,000 people participated in the March on Washington demonstrations. The throng marched to the Mall and listened to Civil Rights leaders, clergyman and others addressed the crowd, including Martin Luther King, Jr.s I Have a Dream speech. (Photo by Steve Schapiro/Corbis via Getty Images)'
                />
              </div>
            )}
            rightPanel={(
              <p {...tabbable} aria-label="But a rising generation of African American activists in groups
              like the Southern Christian Leadership Conference (SCLC) and the
              Student Nonviolent Coordinating Committee (SNCC) disagreed with
              Wallace’s promise." className="activists__text subhead" ref={(ref) => this.activistsTxt = ref}>
                But a rising generation of African American activists in groups
                like the Southern Christian Leadership Conference (SCLC) and the
                Student Nonviolent Coordinating Committee (SNCC) disagreed with
                Wallace’s promise.
              </p>
            )}
          />
        </div>

        <div className="students" ref={(ref) => this.students = ref}>
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            fullWidthPanel={(
              <div className="students__bg" ref={(ref) => this.studentsBg = ref}>
                <Image image={Students} showAria alt="Sign-carrying young blacks sing and chant as they stage a demonstration at the courthouse in Selma, Alabama, Feb. 5, 1965 protesting voter registration. More than 400 of them were arrested and marched off to a compound. (AP Photo/Bill Hudson)"/>
              </div>
            )}
            leftPanel={(
              <div  className="students__left">
                <h3 {...tabbable} ref={(ref) => this.studentsTxt = ref}>
                  They organized and led a number of protests demanding equal rights—<br/><span className="highlight">including the right to vote</span>.
                </h3>
              </div>
            )}
          />
        </div>

        <div className="vertical-timeline" ref={(ref) => this.verticalTimeline = ref}>
          <VerticalTimeline
            timelineData={timelinesYaml.dates}
            progress={verticalTimelinePosition}>
            <Part1 slideId='event-1934'/>
            <Part2 slideId='event-1946'/>
          </VerticalTimeline>
        </div>

        <div className="lafayette" ref={(ref) => this.lafayette = ref}>
          <SplitView
            leftPanel={(
              <div {...tabbable} className="lafayette__text" ref={(ref) => this.lafayetteTab = ref}>
                <h3 ref={(ref) => this.lafayetteTitle = ref}>
                  Bernard LaFayette and Colia Liddell LaFayette of SNCC
                  arrived in Selma in February 1963.
                </h3>
                <p className="alt" aria-label="The Dallas County Voters League (DCVL) invited them to bring
                  young African American students into the voting rights
                  movement." ref={(ref) => this.lafayetteParagraph = ref}>
                  The Dallas County Voters League (DCVL) invited them to bring
                  young African American students into the voting rights
                  movement.
                </p>
              </div>
            )}
            rightPanel={(
              <div className="lafayette__images">
                <div className="bg" ref={(ref) => this.lafayetteMap = ref}>
                  <Image image={LaFayetteMap} alt="LaFayette Map"/>
                </div>
                <div className="couple">
                  <div className="bernard">
                    <p className="alt highlight name" ref={(ref) => this.lafayetteBernardName = ref}>
                      Bernard LaFayette
                    </p>
                    <div className="portrait" ref={(ref) => this.lafayetteBernardPortrait = ref}>
                      <Image image={BernardLaFayette} showAria alt="Bernard LaFayette" caption="Alabama Department of Archives and History. Donated by Alabama Media Group. Photo by Robert Adams or Ed Jones, Birmingham News." />
                    </div>
                  </div>
                  <div className="colia">
                    <div className="portrait" ref={(ref) => this.lafayetteColiaPortrait = ref}>
                      <Image image={ColiaLaFayette} showAria alt="Colia Liddell LaFayette"/>
                    </div>
                    <p className="alt highlight name" ref={(ref) => this.lafayetteColiaName = ref}>
                        Colia Liddell LaFayette
                    </p>
                    <div ref={(ref) => this.lafayetteSncc = ref}>
                      <h3 className="sncc highlight">
                        SNCC
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div className="dvcl" ref={(ref) => this.dvcl = ref}>
          <SplitView
            leftPanel={(
              <div className="dvcl__text" ref={(ref) => this.dvclParagraph = ref} {...tabbable} aria-label="Amelia Boynton and Frederick Reese of DCVL believed that if students started to protest injustice, their parents might follow.">
                <p >
                  Amelia Boynton and Frederick Reese of DCVL believed that if students started to protest injustice, their parents might follow.
                </p>
              </div>
            )}
            rightPanel={(
              <div className="dvcl__images">
                <div className="bg" ref={(ref) => this.dvclMap = ref}>
                  <Image image={DvclMap} alt="DVCL Map"/>
                </div>
                <div className="couple">
                  <div className="frederick">
                    <div className="portrait" ref={(ref) => this.dvclFrederickPortrait = ref}>
                      <Image image={DvclFred} showAria alt="Frederick Reese"/>
                    </div>
                    <p className="alt name highlight" ref={(ref) => this.dvclFrederickName = ref}>
                      Frederick Reese
                    </p>
                  </div>
                  <div className="amelia">
                    <div className="amelia__portrait">
                      <p className="alt name highlight" ref={(ref) => this.dvclAmeliaName = ref}>
                        Amelia Boynton
                      </p>
                      <div className="portrait" ref={(ref) => this.dvclAmeliaPortrait = ref}>
                        <Image image={DvclAmelia} showAria alt="Amelia Boynton"/>
                      </div>
                    </div>
                    <h3 className="highlight" ref={(ref) => this.dvclInitials = ref}>
                      DCVL
                    </h3>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <div className="example" ref={(ref) => this.example = ref}>
          <SplitView
            leftPanel={(
              <div className="example__images">
                <div className="bg" ref={(ref) => this.exampleBg = ref}>
                  <Image image={Protest} alt="Protest" caption="Alabama Department of Archives and History. Donated by Alabama Media Group. Photo by Ed Jones, Birmingham News."/>
                </div>
                <div className="beaten" ref={(ref) => this.exampleBeaten = ref}>
                  <Image image={LaFayetteBeaten} showAria alt="Bernard LaFayette Beaten"/>
                </div>
              </div>
            )}
            rightPanel={(
              <div  className="example__text">
                <h3 {...tabbable} ref={(ref) => this.exampleTxt = ref}>
                  Even after he was brutally beaten in civil rights protests, Bernard LaFayette remained <span className="highlight">committed to the principles of nonviolent action</span>.
                </h3>
                <p  {...tabbable} className="alt" ref={(ref) => this.exampleParagraph = ref}>
                  Many of the Selma students were inspired by his example.
                </p>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  TweenMax.set([
    context.activistsTxt,
    context.studentsBg,
    context.studentsTxt,
    context.verticalTimeline,
    context.lafayetteTitle,
    context.lafayetteParagraph,
    context.lafayetteMap,
    context.lafayetteBernardName,
    context.lafayetteBernardPortrait,
    context.lafayetteSncc,
    context.lafayetteColiaName,
    context.lafayetteColiaPortrait,
    context.dvclMap,
    context.dvclParagraph,
    context.dvclFrederickName,
    context.dvclFrederickPortrait,
    context.dvclAmeliaName,
    context.dvclAmeliaPortrait,
    context.dvclInitials,
    context.example,
    context.exampleBg,
    context.exampleBeaten,
  ], {...hideElementWithAria});

  TweenMax.set([
    context.exampleTxt,
    context.exampleParagraph,
  ], {...hideElement})

  TweenMax.set([
    context.studentsTxt,
    context.lafayetteTab,
    context.dvclParagraph,
    context.exampleTxt,
    context.exampleParagraph,
  ], {...removeTab}); 

  timeline.fromTo(context.activistsTxt, 1.5, {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut});

  timeline.markers.createTimelineMarker();

  timeline.to(context.activists, 1, {...hideElementWithAria});
  timeline.to(context.studentsBg, 1, {...showElemWithAria});
  timeline.fromTo(context.studentsTxt, 1.5, {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut, ...addTab }, '-=0.25');
  timeline.to(context.students, 0.01,  {attr:{'tabindex':-1}});
  timeline.markers.createTimelineMarker();

  timeline.to(context.studentsBg, 1, {...hideElementWithAria});
  timeline.to(context.studentsTxt, 1, {...hideElementWithAria, ...removeTab}, '-=1');

  timeline.to(context.students, 1, {...hideElementWithAria});
  timeline.to(context.verticalTimeline, 1, {...showElemWithAria}, "-=1");
  console.log(timeline.duration())
  placeholder(timeline, timelineDuration);

  // markers for timeline content
  timeline.markers.createTimelineMarker({val: 9.25/duration});
  timeline.markers.createTimelineMarker({val: 13.75/duration});
  timeline.markers.createTimelineMarker({val: 16.75/duration});
  timeline.markers.createTimelineMarker({val: 18.75/duration});

  timeline.to(context.verticalTimeline, 1, {...hideElementWithAria});

  timeline.to([context.lafayetteMap, context.lafayetteBernardPortrait, context.lafayetteColiaPortrait], 1, {...showElemWithAria});
  timeline.fromTo(context.lafayetteTitle, 1.5, {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...addTab, ...easeOut}, '-=0.5');
  timeline.fromTo(context.lafayetteParagraph, 1.5, {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...addTab, ...easeOut}, '-=1.25');

  timeline.fromTo(context.lafayetteBernardName, 1.5,
    {y: '-=50'}, {y: '+=50', ...showElemWithAria, ...easeOut}, '-=1.25'
  );
  timeline.fromTo(context.lafayetteColiaName, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=1.5'
  );
  timeline.fromTo(context.lafayetteSncc, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=1.5'
  );

  timeline.markers.createTimelineMarker();


  timeline.to(context.lafayette, 1, {...hideElementWithAria});
  timeline.to([context.dvclMap, context.dvclFrederickPortrait, context.dvclAmeliaPortrait], 1, {...showElemWithAria});

  timeline.fromTo(context.dvclParagraph, 1.5,
    {y: '-=50'}, {y: '+=50', ...showElemWithAria, ...addTab, ...easeOut}, '-=0.5'
  );

  timeline.fromTo(context.dvclAmeliaName, 1.5,
    {y: '-=50'}, {y: '+=50', ...showElemWithAria, ...easeOut}, '-=1.25'
  );
  timeline.fromTo(context.dvclFrederickName, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=1.25'
  );
  timeline.fromTo(context.dvclInitials, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=1.5'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(context.dvcl, 1, {...hideElementWithAria});
  timeline.to([context.example, context.exampleBg, context.exampleBeaten], 1, {...showElemWithAria});

  timeline.fromTo(context.exampleTxt, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElement,...addTab, ...easeOut}, '-=0.5'
  );
  timeline.fromTo(context.exampleParagraph, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElement,...addTab, ...easeOut}, '-=1.25'
  );
  // console.log(timeline.duration())
};

const query = graphql`
  query {
    SclcBg: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-sclc-bg@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2852) {
          ...selmaFluidImage
        }
      }
    }

    LaFayetteMap: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-map-bg-1@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    BernardLaFayette: file(relativePath: { eq: "chapter-2/scene-6/images/ch2-polaroid-bernard_lafayette.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 690) {
          ...selmaFluidImage
        }
      }
    }

    ColiaLaFayette: file(relativePath: { eq: "chapter-2/scene-6/images/ch2-polaroid-colia_lafayette.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 690) {
          ...selmaFluidImage
        }
      }
    }

    DvclMap: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-map-bg-2@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    DvclFred: file(relativePath: { eq: "chapter-2/scene-6/images/ch2-polaroid-frederick_reese.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 692) {
          ...selmaFluidImage
        }
      }
    }

    DvclAmelia: file(relativePath: { eq: "chapter-2/scene-6/images/ch2-polaroid-amerlia-boynton.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 690) {
          ...selmaFluidImage
        }
      }
    }

    Protest: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-courthouse-protest@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    LaFayetteBeaten: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-lafayette-beaten@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 886) {
          ...selmaFluidImage
        }
      }
    }

    Students: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-image-organized-protests@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...selmaFluidImage
        }
      }
    }

    timelinesYaml(slug:{ eq:"chapter-2-vertical-timeline"}) {
      dates {
        slideId
        h1
        subhead
        p
        isFullBleed
        imgAttribution
        delay
        alt
        img {
          ...mediumImage
        }
      }
    }
  }
`;

const SceneComponent = scene(timeline(C2S6, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
