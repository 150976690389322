export const Chapter2Qa = [
  {
    questionNumber: '2.01',
    question: 'Who had the legal right to vote before 1965? Who was able to exercise that right?',
    answer: `The 15th Amendment stated that any United States citizen had the right to vote and that right could not be restricted based on race, color or previous condition of servitude. White citizens of the United States.`,
  },
  {
    questionNumber: '2.02',
    question: 'What obstacles kept many black citizens (in the South, especially in rural areas) from being able to vote?',
    answer: `Answers will vary and may include: literacy tests,poll taxes, need for an already registered person to “vouch” for a new voter. The registrar’s office was only open twice a month, risk of loss of employment, harassment, intimidation, arrest, physical harm.`,
  },
  {
    questionNumber: '2.03',
    question: 'Why Selma, AL?',
    answer: `Answers will vary and may include: the disparity between number of black citizens in Selma and number of black citizens registered to vote was appalling. (Less than 1% of African Americans in Selma had been able to successfully register.) Selma had an antagonistic sheriff, Jim Clark,who was determined to uphold the Alabama Governor George Wallace’s mantra of "segregation forever."`,
  },
  {
    questionNumber: '2.04',
    question: 'Why did student activists oppose segregation and injustice in Selma, and what actions did they take?',
    answer: `Answers will vary and may include: They were inspired by young activists like the LaFayettes and older activists in DCVL. They were shocked to action by racist violence that targeted and killed young African Americans, even in church. They conducted nonviolent action and organized sit-ins at segregated lunch counters and demonstrated outside the courthouse.`,
  },
  {
    questionNumber: '2.05',
    question: 'What risks did young people take in participating in the voting rights movement?',
    answer: `Answers will vary and may include: They risked physical violence and arrest.`,
  },
  {
    questionNumber: '2.06',
    question: 'What supports did students have?',
    answer: `Students in Selma had the support of SNCC, DCVL, their teachers, a growing national momentum of students, and each other.`,
  },
];
