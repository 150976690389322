import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import ChapterIntro from 'templates/ChapterIntro';
import ChapterPage from 'components/chapter';
import ChapterConclusion from 'components/ChapterConclusion';

import Chapter2Scene1 from 'components/scenes/chapter-2/scene-1/Chapter2Scene1';
import Chapter2Scene2 from 'components/scenes/chapter-2/scene-2/Chapter2Scene2';
import Chapter2Scene3 from 'components/scenes/chapter-2/scene-3/Chapter2Scene3';
import Chapter2Scene4 from 'components/scenes/chapter-2/scene-4/Chapter2Scene4';
import Chapter2Scene5 from 'components/scenes/chapter-2/scene-5/Chapter2Scene5';
import Chapter2Scene6 from 'components/scenes/chapter-2/scene-6/Chapter2Scene6';
import Chapter2Scene7 from 'components/scenes/chapter-2/scene-7/Chapter2Scene7';
import Chapter2Scene8 from 'components/scenes/chapter-2/scene-8/Chapter2Scene8';
import Chapter2Scene9 from 'components/scenes/chapter-2/scene-9/Chapter2Scene9';
import Chapter2IQ from 'components/scenes/chapter-2/scene-q/Chapter2IQ';
import { Chapter2Qa } from 'components/scenes/chapter-2/scene-10/Chapter2Qa';

class Chapter2 extends React.Component {
  render() {
    const { SceneComponent, data } = this.props;
    const { heroImage, Chapter2End } = data;

    const title = 'Democracy Denied in Dallas County'

    return (
      <div className="chapter-2">
        <ChapterPage
          chapterNumber={2}
          title={title}
          scenes={[
            <ChapterIntro
              title={'Chapter 2'}
              subtitle={title}
              hero={heroImage}
              alt="(Original Caption) Dallas County Sheriff James Clark, (left) waves a nightstick as he tells Negroes (Blacks) to leave the county courthouse here. They told Clark they were seeking the voting registrar, and Clark said the registrar's office was closed and that they would have to leave the building. He arrested more than a hundred Negro citizens on the street in front of the courthouse, when they refused to leave after this incident."
            />,
            <Chapter2Scene1 />,
            <Chapter2Scene2 />,
            <Chapter2Scene3 />,
            <Chapter2Scene4 />,
            <Chapter2Scene5 />,
            <Chapter2Scene6 />,
            <Chapter2Scene7 />,
            <Chapter2Scene8 />,
            <Chapter2Scene9 />,
            <Chapter2IQ theme="dark"/>,
            <ChapterConclusion
              image={Chapter2End}
              questions={Chapter2Qa}
              chapterNumber={2}
              nextChapterTitle={'Youth Activism and Voting Rights'}
              theme="dark"
            />,

          ]}
        />
      </div>
    );
  }
}

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "chapter-2/ch-2-image-clark-mlk@2x.jpg" }
    ) {
      ...largeImage
    }
    Chapter2End: file(
      relativePath: { eq: "chapter-2/scene-9/images/chapter-2-end@2x.png" }
    ) {
      ...largeImage
    }
  }
`;

export default Chapter2;
