import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';

import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria
} from 'utils/animation';

import scene from 'components/scene'

import '../scene-1/chapter-2-scene-1.scss';

const C2S4 = class extends React.Component {
  render() {
    const {data} = this.props;
    const {Map, MapZoom} = data;

    return (
      <div className="chapter-2-scene-1">
        <FullScreenContainer>
          <div className="map" ref="mapZoomOut">
            <FullScreenImage
              className="zoom-out" image={Map} aspectRatio={8/5}
              alt="County-level map of Dallas County, Alabama">
              <div className="zoom-out__county">
                <h5 className="highlight">
                  Dallas County
                </h5>
              </div>
            </FullScreenImage>
          </div>

          <div className="map" ref="mapZoomIn">
            <FullScreenImage
              className="zoom-in" image={MapZoom} aspectRatio={8/5} alt="Selma Map">
              <div className="selma-text" ref="mapZoomOutCounty">
                <h3 className="highlight">
                  Selma
                </h3>
              </div>
            </FullScreenImage>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {
    mapZoomIn, mapZoomOut, mapZoomOutCounty
  } = context.refs

  hideAllRefs(context.refs, ['mapZoomOut'])

  TweenMax.set(mapZoomOutCounty, {scale: 0.75})

  timeline.to(mapZoomOut, 1.5, {scale: 3, ...hideElementWithAria, ease: Expo.easeInOut});
  timeline.to(mapZoomIn, 1.5, {scale: 1.5, ease: Expo.easeInOut}, '-=1.5');
  timeline.to(mapZoomIn, 0.5, {...showElemWithAria}, '-=0.75');
  timeline.to(mapZoomOutCounty, 1.5, {scale: 1, ...showElemWithAria, ease: Expo.easeInOut}, '-=1.5');

  placeholder(timeline, 0.5)


}

const query = graphql`
  query {
    Map: file(relativePath: {eq: "chapter-2/scene-1/images/ch-2-image-dallas-county-map-b@2x.png"}) {
      ...largeImage
    }

    MapZoom: file(relativePath: {eq: "chapter-2/scene-1/images/map-bl-00-selma-a.jpg"}) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C2S4, {animation}), {stopAtEnd: false});
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props}/>;
};
