import React from 'react';
import { TweenMax } from 'gsap';
import { useStaticQuery, graphql } from 'gatsby';
import { TimelineMarkers } from 'utils/scene';
import scene from 'components/scene';
import { Image } from 'components/Image';
import timeline from 'components/timelineComponent';
import Modal from 'components/ChapterModal';
import SplitView from 'templates/SplitView';
import { tabbable } from 'utils/accessibility';
import {
  stickyPositions,
  modalWindowBackgrounds
} from 'components/ModalContent';
import {
  placeholder,
  hideAllRefs,
  showElemWithAria,
  hideElementWithAria
} from 'utils/animation';
import ActorPortrait from 'components/ActorPortrait';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import ScrollContainer from 'components/ScrollContainer';

import './chapter-2-scene-9.scss';
import ActModal from './ActModal';

const C2S9 = class extends React.Component {
  render() {
    const { data } = this.props;
    const {
      FreedomDay,
      JohnLewisActor,
      JohnLewis,
      JohnLewisAction,
      Quote,
      Jfk,
      Lbj,
      Signing,
      DoodleMlk,
      DoodleLbj
    } = data;
    return (
      <FullScreenContainer className="chapter-2-scene-9">
        <div
          className="chapter-2-scene-9__freedom"
          ref={ref => (this.freedom = ref)}>
          <SplitView
            fullWidthPanel={
              <div className="freedom-bg">
                <div className="bg">
                  <Image
                    image={FreedomDay}
                    showAria
                    alt='USA. Selma, Alabama. October 7, 1964. The Student Nonviolent Coordinating Committee (SNCC) organized "Freedom Day", an attempt to get residents registered to vote. Chapel singers.'
                  />
                </div>
                <ScrollContainer className="freedom-bg__text">
                  <div className="title">
                    <h1
                      {...tabbable}
                      className="date"
                      ref={ref => (this.freedomDate = ref)}>
                      OCT 7, 1963
                    </h1>
                    <h1
                      {...tabbable}
                      className="highlight"
                      ref={ref => (this.freedomDay = ref)}>
                      FREEDOM DAY
                    </h1>
                  </div>
                  <div  {...tabbable} className="freedom-phrase" aria-label={'But October 7, 1963, would be known as "Freedom Day"—another turning point when large numbers of Selma residents indicated their willingness to resist Jim Clark and Jim Crow segregation.'}>
                    <p
                     
                      className="subhead"
                      {...hideElementWithAria}
                      ref={ref => (this.freedomPhrase = ref)}>
                      But October 7, 1963, would be known as “Freedom
                      Day”—another turning point when large numbers of Selma
                      residents indicated their willingness to resist Jim Clark
                      and Jim Crow segregation.
                    </p>
                  </div>
                </ScrollContainer>
              </div>
            }
          />
        </div>

        <div
          className="chapter-2-scene-9__lewis"
          ref={ref => (this.lewis = ref)}>
          <SplitView
            className="lewis-layout"
            leftPanel={
              <div className="lewis-img" ref={ref => (this.lewisLeft = ref)}>
                <div className="bg" ref={ref => (this.lewisBg = ref)}>
                  <Image image={JohnLewis} showAria alt="John Lewis, Civil Rights Activist" />
                </div>
                <div className="info">
                  <div
                    className="lewis-name"
                    ref={ref => (this.lewisName = ref)}>
                    <h3>John Lewis</h3>
                  </div>
                  <div className="lewis-description">
                    <ul>
                      <li ref={ref => (this.lewisStudent = ref)}>- Student</li>
                      <li ref={ref => (this.lewisActivist = ref)}>
                        - Activist
                      </li>
                      <li ref={ref => (this.lewisCongressman = ref)}>
                        - Future Congressman
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            }
            rightPanel={
              <div
                
                className="lewis-paragraph"
                ref={ref => (this.lewisRight = ref)}>
                <h3 {...tabbable} ref={ref => (this.lewisHead = ref)}>
                  John Lewis was among the young African American protesters in
                  Selma that Fall.
                </h3>
                <p {...tabbable} className="subhead" aria-label="The 23-year-old chair of SNCC was already a veteran of
                  nonviolent protest." ref={ref => (this.lewisSubhead = ref)}>
                  The 23-year-old chair of SNCC was already a veteran of
                  nonviolent protest.
                </p>
              </div>
            }
          />
        </div>

        <div
          className="chapter-2-scene-9__beaten"
          ref={ref => (this.beaten = ref)}>
          <SplitView
            className="beaten-view"
            leftPanel={
              <div
                className="beaten-view__left"
                ref={ref => (this.beatenLeft = ref)}>
                <div
                  className="img-beaten"
                  ref={ref => (this.beatenImgTop = ref)}>
                  <Image showAria image={JohnLewisAction} alt="Two blood-splattered Freedom Riders, John Lewis (left) and James Zwerg (right) stand together after being attacked and beaten by pro-segregationists in Montgomery, Alabama." />
                </div>
              </div>
            }
            rightPanel={
              <div
                
                className="beaten-view__right"
                ref={ref => (this.beatenRight = ref)}>
                <p {...tabbable} className="alt" ref={ref => (this.beatenHead = ref)} aria-label=" He helped lead the 1960 student sit-in movement in Nashville
                  and was beaten unconscious during the 1961 Freedom Rides to
                  integrate southern bus travel.
                  Worth Long, a SNCC colleague, later wrote of him:">
                  He helped lead the 1960 student sit-in movement in Nashville
                  and was beaten unconscious during the 1961 Freedom Rides to
                  integrate southern bus travel.
                  <br />
                  <br />
                  Worth Long, a SNCC colleague, later wrote of him:
                </p>
                <div className="quote" ref={ref => (this.beatenQuote = ref)}>
                  <Image image={Quote} alt="Quote" />
                </div>
                <p {...tabbable} aria-label='John was the most courageous person that I ever worked with in
                  the movement… John would not just follow you into the lion’s
                  den, he would lead you into it.'
                  className="paragraph subhead"
                  ref={ref => (this.beatenParagraph = ref)}>
                  John was the most courageous person that I ever worked with in
                  the movement… John would not just follow you into the lion’s
                  den, he would lead you into it.”
                </p>
              </div>
            }
          />
        </div>

        <div className="chapter-2-scene-9__jfk" ref={ref => (this.jfk = ref)}>
          <SplitView
            className="jfk-view"
            leftPanel={
              <div className="jfk-view__left" ref={ref => (this.jfkLeft = ref)}>
                <div className="jfk-img" ref={ref => (this.jfkImg = ref)}>
                  <Image image={Jfk} showAria alt="Newly-elected President Kennedy posed for first pictures at his White House desk, January 21, 1961, before plunging into a busy round of conferences. (AP Photo/Bill Achatz)" />
                </div>
                <div className="jfk-text" ref={ref => (this.jfkName = ref)}>
                  <h3 className="highlight">
                    President
                    <br />
                    John F. Kennedy
                  </h3>
                </div>
              </div>
            }
            rightPanel={
              <div
                {...tabbable}
                className="jfk-view__right"
                ref={ref => (this.jfkRight = ref)} aria-label="In response to the violence and rising tensions in Selma,
                Birmingham, and elsewhere, President John F. Kennedy tried to pass a Civil Rights bill to
                end segregation in 1963—but it stalled in Congress.">
                <h3 ref={ref => (this.jfkHead = ref)}>
                  In response to the violence and rising tensions in Selma,
                  Birmingham, and elsewhere,
                </h3>
                <p className="alt" ref={ref => (this.jfkParagraph = ref)}>
                  President John F. Kennedy tried to pass a Civil Rights bill to
                  end segregation in 1963—but it stalled in Congress.
                </p>
              </div>
            }
          />
        </div>

        <div className="chapter-2-scene-9__lbj" ref={ref => (this.lbj = ref)}>
          <SplitView
            className="lbj"
            fullWidthPanel={
              <div ref={ref => (this.lbjImg = ref)}>
                <FullScreenImage
                  className="lbj__full"
                  image={Lbj}
                  alignBottom
                  showAria
                  alt="Photograph of Lyndon Johnson, President of the United States of America, signing Civil Rights Act. Behind Johnson is Martin Luther King Jr. Dated 1964. (Photo by: Photo12/UIG via Getty Images)"
                  aspectRatio={8 / 5}>
                  <div
                    className="doodle doodle__lbj"
                    ref={ref => (this.lbjDoodleLbj = ref)}>
                    <h4>LBJ</h4>
                    <div className="arrow">
                      <Image image={DoodleLbj} alt="Doodle LBJ" />
                    </div>
                  </div>
                  <div
                    className="doodle doodle__mlk"
                    ref={ref => (this.lbjDoodleMlk = ref)}>
                    <h4>MLK</h4>
                    <div className="arrow">
                      <Image image={DoodleMlk} alt="Doodle MLK" />
                    </div>
                  </div>
                </FullScreenImage>
              </div>
            }
            leftPanel={
              <div
                {...tabbable}
                aria-label=" After JFK's assassination, President Lyndon B. Johnson (LBJ)
                rallied Congress to pass a comprehensive Civil Rights Act
                (CRA) in July 1964."
                className="lbj__left"
                ref={ref => (this.lbjLeft = ref)}>
                <p className="subhead" ref={ref => (this.lbjParagraph = ref)}>
                  After JFK's assassination, President Lyndon B. Johnson (LBJ)
                  rallied Congress to pass a comprehensive Civil Rights Act
                  (CRA) in July 1964.
                </p>
              </div>
            }
          />
        </div>

        <div className="chapter-2-scene-9__act">
          <SplitView
            className="act"
            fullWidthPanel={
              <div ref={ref => (this.actFull = ref)}>
                <div className="act__full" ref={ref => (this.actImg = ref)}>
                  <Image image={Signing} alt="Signing" />
                </div>
                <ScrollContainer className="act__left">
                  <div {...tabbable} className="act-container">
                    <div
                      className="act-title"
                      ref={ref => (this.actTitle = ref)}>
                      <h2>
                        The 1964 Civil Rights Act was a major victory for the
                        civil rights movement.
                      </h2>
                    </div>
                    <p
                      className="subhead"
                      ref={ref => (this.actParagraph = ref)}>
                      It{' '}
                      <span className="highlight">
                        prohibited racial discrimination
                      </span>{' '}
                      in public facilities, government, and employment, and{' '}
                      <span className="highlight">
                        abolished all Jim Crow segregation laws
                      </span>{' '}
                      in the South.
                    </p>
                    <br />
                    <Modal
                      className="act-mod"
                      stickyPosition={stickyPositions.NO_STICKY}>
                      <ActModal />
                    </Modal>
                  </div>
                </ScrollContainer>
              </div>
            }
          />
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    FreedomDay: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-image-freedom-day@2x.png"
      }
    ) {
      ...largeImage
    }

    JohnLewisActor: file(
      relativePath: { eq: "chapter-2/scene-9/images/movie-still@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1002) {
          ...selmaFluidImage
        }
      }
    }

    JohnLewis: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-image-john-lewis@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    JohnLewisAction: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-image-john-lewis-action@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Jfk: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-image-jfk-oval-office@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Lbj: file(
      relativePath: { eq: "chapter-2/scene-9/images/ch-2-image-cra@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Signing: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-image-signing-cra@2x.png"
      }
    ) {
      ...largeImage
    }

    DoodleLbj: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-doodle-arrow-lbj@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...selmaFluidImage
        }
      }
    }

    DoodleMlk: file(
      relativePath: {
        eq: "chapter-2/scene-9/images/ch-2-doodle-arrow-mlk@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 160) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const animation = ({ timeline, context, easeOut, easeIn }) => {
  const actModal = '.act-mod';
  TweenMax.set(
    [
      context.lewisLeft,
      context.lewisRight,
      context.beatenLeft,
      context.beatenRight,
      context.jfkLeft,
      context.jfkRight,
      context.lbjLeft,
      context.actFull,
      context.freedomDate,
      context.freedomDay,
      context.freedomPhrase,
      context.lewisBg,
      context.lewisName,
      context.lewisStudent,
      context.lewisActivist,
      context.lewisCongressman,
      context.lewisHead,
      context.lewisSubhead,
      context.beatenImgTop,
      context.beatenHead,
      context.beatenQuote,
      context.beatenParagraph,
      context.jfkImg,
      context.jfkName,
      context.jfkHead,
      context.jfkParagraph,
      context.lbjImg,
      context.lbjDoodleLbj,
      context.lbjDoodleMlk,
      context.lbjParagraph,
      context.actImg,
      context.actTitle,
      context.actParagraph,
      actModal
    ],
    { ...hideElementWithAria }
  );

  timeline.fromTo(
    context.freedomDate,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut }
  );
  timeline.fromTo(
    context.freedomDay,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    context.freedomPhrase,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.markers.createTimelineMarker();

  timeline.to(context.freedom, 1, { ...hideElementWithAria });

  timeline.to([context.lewisLeft, context.lewisBg, context.lewisRight], 1, {
    ...showElemWithAria
  });

  timeline.fromTo(
    context.lewisHead,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    context.lewisSubhead,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    context.lewisName,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    context.lewisStudent,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );
  timeline.fromTo(
    context.lewisActivist,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );
  timeline.fromTo(
    context.lewisCongressman,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(context.lewis, 1, { ...hideElementWithAria });

  timeline.to(
    [context.beatenLeft, context.beatenImgTop, context.beatenRight],
    1,
    { ...showElemWithAria }
  );

  timeline.fromTo(
    context.beatenHead,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    context.beatenQuote,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    context.beatenParagraph,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.5'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(context.beaten, 1, { ...hideElementWithAria });

  timeline.to(
    [context.jfkLeft, context.jfkImg, context.jfkRight, context.jfkName],
    1,
    { ...showElemWithAria }
  );

  timeline.fromTo(
    context.jfkHead,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    context.jfkParagraph,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.markers.createTimelineMarker();

  timeline.to(context.jfk, 1, { ...hideElementWithAria });

  timeline.to([context.lbjImg, context.lbjLeft], 1, { ...showElemWithAria });

  timeline.fromTo(
    context.lbjParagraph,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    context.lbjDoodleMlk,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    context.lbjDoodleLbj,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );
  timeline.markers.createTimelineMarker();

  timeline.to(context.lbj, 1, { ...hideElementWithAria });

  timeline.to([context.actFull, context.actImg], 1, { ...showElemWithAria });

  timeline.fromTo(
    context.actTitle,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut }
  );
  timeline.fromTo(
    context.actParagraph,
    1.5,
    { y: '+=50' },
    { ...showElemWithAria, y: '-=50', ...easeOut },
    '-=1.25'
  );

  timeline.to(actModal, 0.5, { ...showElemWithAria }, '-=0.75');
};

const SceneComponent = scene(timeline(C2S9, { animation }));
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
