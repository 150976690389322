import React from 'react';

import './selma-scene-1-modal.scss';

const SelmaScene1Modal = class extends React.Component {
  render() {
    return (
      <div className="selma-scene-1-modal">
        <p className="subhead">
          Of the 15,000 African Americans in Selma, only 130 were registered to vote—that’s…
        </p>
        <h2>
          LESS THAN 1% OF THE BLACK ADULT POPULATION OF SELMA.
        </h2>
        <h5>
          WHAT IS 1% OF:
        </h5>
        <p className="alt">
          School aged children in the United States?<br/>
          People in your town?<br/>
          Students in your school?
        </p>
      </div>
    );
  }
};

export default SelmaScene1Modal;
