import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Image from 'components/Image';

import './chapter-2-scene-5.scss';

const VoteRegistrationModal = ({data}) => (
  <div className="vote-registration-modal">
    <div className="vote-registration-modal__image">
      <Image className="sand-bg" image={data.SandTimerBg} alt="Sand Timer Background"/>
      <Image className="sand" image={data.SandTimer} alt="Sand Timer Foreground"/>
    </div>
    <div className="vote-registration-modal__text">
      <div className="subhead">
        The Dallas County election board only met twice a month—and the maximum number of applications that could be processed<br/>was <span className="highlight">30 per day</span>.
      </div>
      <p className="alt">
        At that rate it <span className="highlight">would take 10 years</span> for black registrations to reach white registrations—assuming applications were successful.
      </p>
    </div>
  </div>
);

const query = graphql`
  query {
    SandTimerBg: file(relativePath: { eq: "chapter-2/scene-5/images/ch-2-image-red-sand@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...selmaFluidImage
        }
      }
    }
    SandTimer: file(relativePath: { eq: "chapter-2/scene-5/images/ch-2-image-sand-timer@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <VoteRegistrationModal data={data} {...props} />;
};
