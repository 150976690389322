import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import SplitView from 'templates/SplitView';
import {Image} from 'components/Image';
import timeline from 'components/timelineComponent';
import ImageSwap from 'components/ImageSwap';
import FullScreenContainer from 'components/FullScreenContainer';
import {tabbable} from 'utils/accessibility'

import {getPercentageBetweenBounds} from 'utils';
import {TimelineMarkers} from 'utils/scene';
import scene from 'components/scene';
import {
  placeholder, showElemWithAria, hideElementWithAria,
} from 'utils/animation';

import './chapter-2-scene-8.scss';

const imageSwapStart = (0/3.75);
const imageSwapEnd = (1.25/3.75);

const votingSwapStart = 0.6;
const votingSwapEnd = 1;


const C2S8 = class extends React.Component {

  render() {
    const {position, data} = this.props;
    const {Registration, Registration1, Arrest1, Arrest2} = data;
    const imageSwapProgress = getPercentageBetweenBounds(position, imageSwapStart, imageSwapEnd);
    const votingSwapProgress = getPercentageBetweenBounds(position, votingSwapStart, votingSwapEnd);

    return (
      <FullScreenContainer className="chapter-2-scene-8">
        <div className="chapter-2-scene-8__beating" ref={(ref) => this.beating = ref}>
          <div className="beating-content">
            <SplitView
              fullWidthPanel={(
                <div className="bg" ref={(ref) => this.beatingImg = ref}>
                  <ImageSwap
                    image={Arrest1}
                    image2={Arrest2}
                    showAria
                    alt="USA. Selma, Alabama. October 7, 1964. Sheriff Jim Clark arrests two demonstrators who displayed placards on the steps of the federal building in Selma."
                    position={imageSwapProgress}
                  />
                </div>
              )}
              leftPanel={(
                <div  className="beating-text">
                  <p {...tabbable} className="subhead" ref={(ref) => this.beatingHead = ref} aria-label="In October, a major SNCC rally for voting rights drew hundreds of spectators.">
                    In October, a major SNCC rally for voting rights drew hundreds of spectators.
                  </p>
                  <h3 {...tabbable} ref={(ref) => this.beatingParagraph = ref}>
                    Dallas County Sheriff Jim Clark and his men beat and attacked the protesters with cattle prods and arrested many of them.
                  </h3>
                </div>
              )}
            />
          </div>
        </div>

        <div className="chapter-2-scene-8__voting">
          <div className="bg" ref={(ref) => this.votingImage = ref}>
            <ImageSwap
              image={Registration}
              image2={Registration1}
              showAria
              alt='USA. Selma, Alabama. October 7, 1963. The Student Nonviolent Coordinating Committee (SNCC) organized "Freedom Day", an attempt to get residents registered to vote. Howard Zinn covering the days events.'
              position={votingSwapProgress}
            />
          </div>
          <div className="txt">
            <SplitView
              rightPanel={(
                <div {...tabbable} ref={ref => this.votingText = ref} className="voting-text">
                  <p className="subhead" ref={(ref) => this.votingHead = ref}>
                    The next day, 350 black Selma citizens stood in line all day at the courthouse to register to vote.
                  </p>
                  <p className="subhead highlight" ref={(ref) => this.votingParagraph = ref}>
                    They were unsuccessful.
                  </p>

                </div>
              )}
            />
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    Registration: file(relativePath: { eq: "chapter-2/scene-8/images/ch-2-image-registration-a@2x.jpg" }) {
      ...largeImage
    }

    Registration1: file(relativePath: { eq: "chapter-2/scene-8/images/ch-2-image-registration-b@2x.jpg" }) {
      ...largeImage
    }

    Arrest1: file(relativePath: { eq: "chapter-2/scene-8/images/ch2-image-clark_arrests_protestors-a.jpg" }) {
      ...largeImage
    }

    Arrest2: file(relativePath: { eq: "chapter-2/scene-8/images/ch2-image-clark_arrests_protestors-b.jpg" }) {
      ...largeImage
    }
  }
`;


const animation = ({timeline, context, easeOut, easeIn}) => {
  TweenMax.set(context.beatingImg, {...showElemWithAria});
  TweenMax.set([
    context.beatingHead,
    context.beatingParagraph,
    context.votingImage,
    context.votingHead,
    context.votingParagraph,
    context.votingText
  ], {...hideElementWithAria});


  timeline.to(context.beatingImg, 0.5, {...showElemWithAria});
  timeline.fromTo(context.beatingHead, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, '-=0.5');
  timeline.fromTo(context.beatingParagraph, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, '-=1.25');
  timeline.markers.createTimelineMarker();

  timeline.to(context.beating, 1, {...hideElementWithAria});
  timeline.to([context.votingImage, context.votingText], 1, {...showElemWithAria}, '-=0.5');

  timeline.fromTo(context.votingHead, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut});
  timeline.fromTo(context.votingParagraph, 1.5, {y: '+=50'}, {...showElemWithAria, y: '-=50', ...easeOut}, '-=1.25');

};

const SceneComponent = scene(timeline(C2S8, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
