import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import timeline from 'components/timelineComponent';
import {Image} from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import {AppSettings} from 'components/Layout';
import SplitView from 'templates/SplitView';
import {getPercentageBetweenBounds} from 'utils';
import {
  placeholder, showElemWithAria, hideElementWithAria, showElement, hideElement, setThenRemoveFocus
} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import scene from 'components/scene';
import VideoAnimation from 'components/VideoAnimation';

import explosion from './video/explosion.mp4';

import './chapter-2-scene-7.scss';

const C2S7 = class extends React.Component {
  static contextType = AppSettings

  render() {
    const {data, position} = this.props;
    const {
      Explosion, Bombing, GirlsFuneral, Carole, Denise,
      Cynthia, Addie, Carter, SelmaMap,
    } = data;

    const videoProgress = getPercentageBetweenBounds(position, (2/16.5), (2.5/16.5));

    return (
      <div className="chapter-2-scene-7">
        <div className="chapter-2-scene-7__explosion" ref={(ref) => this.explosion = ref}>
          <FullScreenContainer className="chapter-2-scene-7__explosion__container">
            <div {...tabbable} className="subhead" ref={(ref) => this.explosionText = ref}>
              They followed that example in September 1963,<br/>after the <span className="highlight" ref={(ref) => this.explosionRed = ref}>KKK bombed the 16th Street Baptist Church in Birmingham</span>.
            </div>
            <div className="fg-explosion" ref={(ref) => this.explosionFg = ref} aria-label="Video depicting explosion">
              {videoProgress > 0 &&
                <VideoAnimation video={explosion} sections={[
                  {
                    breakpoints: [0, 1],
                    keyframes: [0, 1]
                  }
                ]} progress={videoProgress} />
              }
            </div>
          </FullScreenContainer>
        </div>

        <div className="chapter-2-scene-7__girls" ref={(ref) => this.girlsContent = ref}>
          <div className="girls-info">
            <SplitView
              fullWidthPanel={
                <div className="girls-photos">
                  <div className="girl g1" ref={(ref) => this.girlsCarolePortrait = ref}>
                    <Image image={Carole} showAria alt="Carole Robertson"/>
                  </div>
                  <div className="girl g2" ref={(ref) => this.girlsDenisePortrait = ref}>
                    <Image image={Denise} showAria alt="Denise McNair"/>
                  </div>
                  <div className="girl g3" ref={(ref) => this.girlsCynthiaPortrait = ref}>
                    <Image image={Cynthia} showAria alt="Cynthia Wesley"/>
                  </div>
                  <div className="girl g4" ref={(ref) => this.girlsAddiePortrait = ref}>
                    <Image image={Addie}  showAria alt="Addie Mae Collins"/>
                  </div>
                </div>
              }
              leftPanel={(
                <div className="funeral" ref={(ref) => this.girlsFuneral = ref}>
                  <Image image={GirlsFuneral} showAria alt="USA. Birmingham, Alabama. 1963. Funeral for one of the four girls killed in the KKK bombing of the 16th Street Baptist church."/>
                </div>
              )}
              rightPanel={(
                <div {...tabbable} ref={(ref) => this.girlsText = ref} className="description" aria-label="Four young girls,Carole Robertson, Denise McNair, Cynthia Wesley, and Addie Mae Collins were killed that day">
                  <p className="subhead">
                    <span ref={(ref) => this.girlsTitle = ref} aria-hidden="true">Four young girls— </span><br/><br/><span aria-hidden="true" className="highlight" ref={(ref) => this.girlsCarole = ref}>Carole Robertson</span><span ref={(ref) => this.girlsCaroleComma = ref}>,</span> <span className="highlight" aria-hidden="true" ref={(ref) => this.girlsDenise = ref}>Denise McNair</span><span ref={(ref) => this.girlsDeniseComma = ref}>,</span> <span className="highlight" aria-hidden="true" ref={(ref) => this.girlsCynthia = ref}>Cynthia Wesley</span><span ref={(ref) => this.girlsCynthiaComma = ref}>, and</span> <span className="highlight" aria-hidden="true" ref={(ref) => this.girlsAddie = ref}>Addie Mae Collins</span><br/><br/><span aria-hidden="true" ref={(ref) => this.girlsParagraph = ref}>—were killed that day.</span><br/><br/><br/><br/><br/><br/><br/><br/>
                  </p>
                </div>
              )}
            />
          </div>

        </div>

        <div className="chapter-2-scene-7__map" ref={(ref) => this.map = ref}>
          <SplitView
            fullWidthPanel={(
              <div className="map__bg">
                <Image image={SelmaMap} alt="Tilted street-level map of Selma, Alabama"/>
              </div>
            )}
            leftPanel={(
              <div {...tabbable} className="map__left">
                <h3 ref={(ref) => this.mapText1 = ref}>
                  Selma students protested the 16th Street Baptist Church bombing by picketing Carter Drug. Co., a segregated business downtown.
                </h3>
                <p className="alt" ref={(ref) => this.mapText2 = ref}>
                  After one student, Willie Robinson, was beaten and four other students were arrested, <span className="highlight">the protests grew</span>.<br/><br/>Up to 1000 Selma college, high school, and even grade school students left school to take part in a demonstration against segregated lunch counters, restaurants, and libraries.
                </p>
              </div>
            )}
            rightPanel={(
              <div className="map__right">
                <div className="carter-img" ref={(ref) => this.mapCarter = ref}>
                  <Image image={Carter} showAria alt="Carter Drug. Co. sign"/>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
};

const query = graphql`
  query {
    Explosion: file(relativePath: { eq: "chapter-2/scene-7/images/ch-2-image-explosion@2x.png" }) {
      ...largeImage
    }

    Bombing: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-16th_st_church_bombing.jpg" }) {
      ...largeImage
    }

    GirlsFuneral: file(relativePath: { eq: "chapter-2/scene-7/images/ch-2-image-bombing-funeral@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1438) {
          ...selmaFluidImage
        }
      }
    }

    Carole: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-carol_robertson.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 468) {
          ...selmaFluidImage
        }
      }
    }

    Denise: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-denise_mcnair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 468) {
          ...selmaFluidImage
        }
      }
    }

    Cynthia: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-cynthia_wesley.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 468) {
          ...selmaFluidImage
        }
      }
    }

    Addie: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-addie_mae_collins.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 468) {
          ...selmaFluidImage
        }
      }
    }

    Carter: file(relativePath: { eq: "chapter-2/scene-7/images/ch2-image-carter_drug_sign.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1214) {
          ...selmaFluidImage
        }
      }
    }

    SelmaMap: file(relativePath: { eq: "chapter-2/scene-7/images/ch-2-bg-carter-drug-map@2x.png" }) {
      ...largeImage
    }

    ArrowDot: file(relativePath: { eq: "chapter-2/scene-7/images/doodle-arrow-dot@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 912) {
          ...selmaFluidImage
        }
      }
    }
  }
`;


const animation = ({timeline, context, easeOut, easeIn}) => {
  const {screenHeight} = context.context;

  TweenMax.set(context.explosionText, {...hideElementWithAria, y: 100});
  TweenMax.set([
    context.explosionFg,
    context.girlsFuneral,
    context.girlsText,
    context.map,
    context.mapCarter,
    context.mapText1,
    context.mapText2,
  ], {...hideElementWithAria});
  // TweenMax.set(context.explosionFgImg, {x: -screenWidth});

  TweenMax.set([
    context.girlsTitle,
    context.girlsCarole,
    context.girlsCaroleComma,
    context.girlsDenise,
    context.girlsDeniseComma,
    context.girlsCynthia,
    context.girlsCynthiaComma,
    context.girlsAddie,
    context.girlsParagraph,
  ], {...hideElement});

  TweenMax.set(context.girlsCarolePortrait, {...hideElementWithAria, rotation: -10});
  TweenMax.set(context.girlsDenisePortrait, {...hideElementWithAria, rotation: 0});
  TweenMax.set(context.girlsCynthiaPortrait, {...hideElementWithAria, rotation: -9});
  TweenMax.set(context.girlsAddiePortrait, {...hideElementWithAria, rotation: 11});

  timeline.to(context.explosionText, 1.5, {...showElemWithAria, y: 0, ...easeOut});

  timeline.markers.createTimelineMarker();
  timeline.to(context.explosionRed, 1, {className: '-=highlight'});

  timeline.to(context.explosionFg, 0.2, {...showElemWithAria, onComplete: () => {
    setThenRemoveFocus(context.explosionFg)
  }});
  timeline.to(context.explosionText, 0.2, {...hideElementWithAria});

  timeline.markers.createTimelineMarker();

  timeline.to(context.explosion, 1, {...hideElementWithAria});

  timeline.to([context.girlsFuneral, context.girlsText], 1, {...showElemWithAria});
  timeline.to(context.girlsTitle, 0.5, {...showElement}, '-=0.5');

  placeholder(timeline, 0.5);

  timeline.to(context.girlsCarole, 0.5, {...showElement});
  timeline.to(context.girlsCaroleComma, 0.5, {...showElement}, '-=0.5');
  timeline.to(context.girlsCarolePortrait, 0.5, {...showElement}, '-=0.5');

  placeholder(timeline, 0.5);

  timeline.to(context.girlsDenise, 0.5, {...showElement});
  timeline.to(context.girlsDeniseComma, 0.5, {...showElement}, '-=0.5');
  timeline.to(context.girlsDenisePortrait, 0.5, {...showElement}, '-=0.5');

  placeholder(timeline, 0.5);

  timeline.to(context.girlsCynthia, 0.5, {...showElement});
  timeline.to(context.girlsCynthiaComma, 0.5, {...showElement}, '-=0.5');
  timeline.to(context.girlsCynthiaPortrait, 0.5, {...showElement}, '-=0.5');

  placeholder(timeline, 0.5);

  timeline.to(context.girlsAddie, 0.5, {...showElement});
  timeline.to(context.girlsAddiePortrait, 0.5, {...showElement}, '-=0.5');

  placeholder(timeline, 1);

  timeline.to(context.girlsParagraph, 1, {...showElement});

  timeline.markers.createTimelineMarker();

  timeline.to(context.girlsFuneral, 1, {...hideElementWithAria});
  timeline.to(context.girlsTitle, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsParagraph, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsCarole, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsCaroleComma, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsDenise, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsDeniseComma, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsCynthia, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsCynthiaComma, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsAddie, 1, {...hideElementWithAria}, '-=1');

  timeline.to(context.girlsCarolePortrait, 1, {...hideElementWithAria});
  timeline.to(context.girlsDenisePortrait, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsCynthiaPortrait, 1, {...hideElementWithAria}, '-=1');
  timeline.to(context.girlsAddiePortrait, 1, {...hideElementWithAria}, '-=1');

  timeline.to(context.girlsContent, 1, {...hideElementWithAria}, '-=1');

  placeholder(timeline, 1);

  TweenMax.set(context.mapCarter, {rotation: -6})
  timeline.to([context.map, context.mapCarter], 1, {...showElemWithAria});
  timeline.fromTo(context.mapText1, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=0.5'
  );
  timeline.fromTo(context.mapText2, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, '-=1.25'
  );

  timeline.markers.createTimelineMarker();
};

const SceneComponent = scene(timeline(C2S7, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
