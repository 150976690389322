import React, { Component } from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Timeline from 'components/timelineComponent';
import { Image } from 'components/Image';

import {
  showElemWithAria, hideElementWithAria, placeholder
} from 'utils/animation';

import './chapter-2-scene-6-part-1.scss';
import { TweenMax } from 'gsap';

class Chapter2Scene6Part1 extends React.Component {
  render() {
    const { PauliMurray } = this.props.data
    return (
      <div className="scene6p1">
        <div className="scene6p1__img">
          <Image image={PauliMurray} alt="Pauli Murray" showAria={this.props.active} caption="Schlesinger Library, Radcliffe Institute, Harvard University"/>
        </div>
        <div className="scene6p1__paulli" ref="paulli">
          <h3>Paulli Murray</h3>
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {
  const { paulli } = context.refs
  TweenMax.set(paulli, {...hideElementWithAria})
  timeline.fromTo(paulli, 1.5, {y: '+=50'}, {y:'-=50', ...showElemWithAria, ...easeOut})
}

const query = graphql`
  query {
    PauliMurray: file(relativePath: { eq: "chapter-2/scene-6/images/ch-2-body-timeline-1943@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }
  }
`

const SceneComponent = Timeline(Chapter2Scene6Part1, {animation})

export default (props) => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props}/>
};
