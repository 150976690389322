import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {placeholder, hideAllRefs, showElemWithAria, hideElementWithAria} from 'utils/animation';
import {TimelineMarkers} from 'utils/scene'
import scene from 'components/scene'
import {tabbable} from 'utils/accessibility'

import './chapter-2-scene-1.scss';

const C2S1 = class extends React.Component {
  render() {
    const {Map, MapZoom} = this.props.data;
    return (
      <div className="chapter-2-scene-1">
        <FullScreenContainer>
          <div className="map" ref="mapZoomIn">
            <Image
              className="zoom-in"
              image={MapZoom}
              sizes="100vw"
              alt="Street-level map of Selma, Alabama"
            />
            <div className="selma-text" ref="selmaText">
              <h3 className="highlight">Selma</h3>
            </div>
          </div>
          <div className="map" ref="mapZoomOut">
            <Image
              className="zoom-out"
              image={Map}
              sizes="100vw"
              alt="Dallas County Zoom out"
            />

            <div className="zoom-out__county">
              <h5 className="highlight" ref="mapZoomOutCounty">
                  Dallas County
              </h5>
            </div>

            <div {...tabbable} className="zoom-out__copies">
              <h4 ref="mapZoomOutTitle">
                  In Dallas County, Alabama, <span className="highlight">discrimination was rampant</span> against African American voters.
              </h4>
              <p ref="mapZoomOutParagraph" className="alt">
                Even though the 1957 Civil Rights Act was meant to be the law of the land,
                people like Annie Lee Cooper were still denied the basic right to vote.
              </p>
            </div>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {
    selmaText, mapZoomIn, mapZoomOut, mapZoomOutParagraph, mapZoomOutTitle, mapZoomOutCounty,
  } = context.refs;

  hideAllRefs(context.refs, ['mapZoomIn', 'selmaText']);

  TweenMax.set(mapZoomOut, {scale: 4, ...hideElementWithAria});
  TweenMax.set(mapZoomIn, {scale: 2});
  TweenMax.set(selmaText, {scale: 1});

  timeline.to(mapZoomIn, 1.5, {scale: 0.75, ...hideElementWithAria, ease: Expo.easeInOut});
  timeline.to(selmaText, 1.5, {scale: 1.5, ease: Expo.easeInOut}, '-=1.5');
  timeline.to(mapZoomOut, 1.5, {scale: 1, ease: Expo.easeInOut}, '-=1.5');
  timeline.to(mapZoomOut, 0.5, {...showElemWithAria}, '-=0.75');

  timeline.to(mapZoomOutCounty, 1, {...showElemWithAria});
  timeline.fromTo(mapZoomOutTitle, 1, {y: '+=20'}, {y: '-=20', ...showElemWithAria, ...easeOut}, '-=1');
  timeline.fromTo(mapZoomOutParagraph, 1, {y: '+=20'}, {y: '-=20', ...showElemWithAria, ...easeOut}, '-=0.5');

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    Map: file(
      relativePath: { eq: "chapter-2/scene-1/images/ch-2-image-dallas-county-map-b@2x.png" }
    ) {
      ...mediumImage
    }

    MapZoom: file(
      relativePath: { eq: "chapter-2/scene-1/images/map-bl-00-selma-a.jpg" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C2S1, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props}/>;
};
