import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import timeline from 'components/timelineComponent';
import SplitView from 'templates/SplitView';
import Image from 'components/Image';
import Modal from 'components/ChapterModal';
import {stickyPositions, modalWindowBackgrounds} from 'components/ModalContent';
import ActorPortrait from 'components/ActorPortrait';
import FullScreenContainer from 'components/FullScreenContainer';

import scene from 'components/scene'
import {
  placeholder, showElemWithAria, hideElementWithAria, showElement, hideElement
} from 'utils/animation';
import ScrollContainer from 'components/ScrollContainer';
import {tabbable} from 'utils/accessibility'

import VoteRegistrationModal from './VoteRegistrationModal';

import './chapter-2-scene-5.scss';

class C2S5 extends React.Component {
  render() {
    const { data } = this.props;
    const {
      VotingRegistration, Wallace, Segregation1, Segregation2, Segregation3, TimRoth,
    } = data;

    return (
      <div className="chapter-2-scene-5">
        <div
          className="vote-registration"
          ref={ref => (this.voteRegistration = ref)}>
          <SplitView
            fullWidthPanel={
              <ScrollContainer className="vote-registration__text">
                <div className="text-box">
                  <div {...tabbable} className="subhead" ref={(ref) => this.voteRegDescription = ref}>
                    Additionally, in Dallas County, the registrar’s office was open only two days a month, <span className="highlight">limiting the number of applications that could be processed</span>.<br/><br/>
                    Such voter suppression tactics stymied the Dallas County Voters League, Selma's oldest voting rights group.
                  </div>
                  <div ref={(ref) => this.factFocus = ref}/>
                  <div ref={(ref) => this.fact = ref} className="fact">
                    <div {...tabbable} className="fact__container">
                      <h3 ref={(ref) => this.voteRegCounty = ref}>
                        New black voter registration in the county
                      </h3>
                      <h1 ref={(ref) => this.voteRegDecline = ref}>
                        declined to zero in 1962.
                      </h1>
                      <Modal
                        className="vote-registration__modal"
                        stickyPosition={stickyPositions.NO_STICKY}>
                        <VoteRegistrationModal />
                      </Modal>
                    </div>
                  </div>
                </div>
              </ScrollContainer>
            }
            rightPanel={
              <div
                className="vote-registration__image"
                ref={ref => (this.voteRegImg = ref)}>
                <Image image={VotingRegistration} showAria alt="View of a citizens as they register to vote, Greensboro, Alabama, 1965. (Photo by PhotoQuest/Getty Images)" />
              </div>
            }
          />
        </div>

        <div className="wallace" ref={ref => (this.wallace = ref)}>
          <FullScreenContainer>
            <div className="wallace__bkgs">
              <div
                className="bkg"
                ref={ref => (this.wallaceSegregation1 = ref)}>
                <Image image={Segregation1} alt="Segregation Now" />
              </div>
              <div
                className="bkg"
                ref={ref => (this.wallaceSegregation2 = ref)}>
                <Image image={Segregation2} alt="Segregation Tomorrow" />
              </div>
              <div
                className="bkg"
                ref={ref => (this.wallaceSegregation3 = ref)}>
                <Image image={Segregation3} alt="Segregation Forever" />
              </div>
            </div>

            <div className="wallace__img" ref={ref => (this.wallaceImg = ref)}>
              <Image image={Wallace} showAria alt="George Wallace" />
            </div>

            <h3
              className="wallace__title"
              {...tabbable}
              ref={ref => (this.wallaceTitle = ref)}>
              In 1963, George Wallace was inaugurated as the Governor of
              Alabama, promising:
            </h3>

            <div {...tabbable} ref={ref => (this.wallacePhrases = ref)} className="wallace__phrases">
              <h2 ref={ref => (this.wallacePhrase1 = ref)}>SEGREGATION NOW,</h2>
              <h2 ref={ref => (this.wallacePhrase2 = ref)}>
                SEGREGATION TOMORROW,
              </h2>
              <h2 ref={ref => (this.wallacePhrase3 = ref)}>
                SEGREGATION <span className="highlight">FOREVER</span>.
              </h2>
              <br></br>
            </div>
          </FullScreenContainer>
        </div>
      </div>
    );
  }
}

const query = graphql`
  query {
    VotingRegistration: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-2-image-voting-registration@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Wallace: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-2-image-george-wallace-segregation@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Segregation1: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-3-image-segregation-a@2x.png"
      }
    ) {
      ...largeImage
    }

    Segregation2: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-3-image-segregation-b@2x.png"
      }
    ) {
      ...largeImage
    }

    Segregation3: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-3-image-segregation-c@2x.png"
      }
    ) {
      ...largeImage
    }

    TimRoth: file(
      relativePath: {
        eq: "chapter-2/scene-5/images/ch-2-image-tim-roth@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1002) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {
    voteRegDescription,
    fact,
    factFocus,
    voteRegCounty,
    voteRegDecline,
    voteRegImg,
    wallaceImg,
    wallaceTitle,
    wallaceSegregation1,
    wallaceSegregation2,
    wallaceSegregation3,
    wallacePhrases,
    wallacePhrase1,
    wallacePhrase2,
    wallacePhrase3,
    voteRegistration
  } = context;
  const voteRegModal = '.vote-registration__modal';

  TweenMax.set([
    voteRegModal,
    fact,
    factFocus,
    voteRegDescription,
    voteRegCounty,
    voteRegDecline,
    wallaceImg,
    wallaceTitle,
    wallaceSegregation1,
    wallaceSegregation2,
    wallaceSegregation3,
    wallacePhrases,
  ], {...hideElementWithAria});

  TweenMax.set([
    wallacePhrase1,
    wallacePhrase2,
    wallacePhrase3
  ], {...hideElement});

  timeline.fromTo(voteRegDescription, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}
  );

  timeline.markers.createTimelineMarker();

  timeline.to([fact, factFocus], 0.01, {...showElemWithAria, onComplete: () => requestAnimationFrame( () => {
    factFocus.setAttribute('tabindex', 0)
    factFocus.focus()
    factFocus.removeAttribute('tabindex')
  })})
  timeline.fromTo(voteRegCounty, 1.5,
    {y: '+=100'}, {y: '-=100', ...showElemWithAria, ...easeOut }
  );
  timeline.fromTo(voteRegDecline, 1.5,
    {y: '+=100'}, {y: '-=100', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.to(voteRegModal, 0.5, {...showElemWithAria }, "-=0.5");

  timeline.markers.createTimelineMarker();

  timeline.to(voteRegistration, 1, {...hideElementWithAria});

  timeline.fromTo(wallaceImg, 1.5, {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut});
  timeline.fromTo(wallaceTitle, 1.5, {y: '-=50'}, {y: '+=50', ...showElemWithAria, ...easeOut}, "-=1.25");

  timeline.markers.createTimelineMarker();

  timeline.to(wallaceTitle, 0.5, {...hideElementWithAria});

  timeline.to(wallaceSegregation1, 0.5, {...showElemWithAria});
  timeline.set(wallacePhrases, {...showElemWithAria})
  timeline.fromTo(wallacePhrase1, 1.5, {y: '+=20'}, {y: '-=20', ...showElement, ...easeOut}, '-=0.5');

  timeline.to(wallaceSegregation2, 0.5, {...showElement});
  timeline.fromTo(wallacePhrase2, 1.5, {y: '+=20'}, {y: '-=20', ...showElement, ...easeOut}, '-=0.5');

  timeline.to(wallaceSegregation3, 0.5, {...showElement});
  timeline.fromTo(wallacePhrase3, 1.5, {y: '+=20'}, {y: '-=20', ...showElement, ...easeOut}, '-=0.5');

};

const SceneComponent = scene(timeline(C2S5, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
